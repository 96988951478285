/* @import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap'); */

/* Custom css */
.container-fluid {
  padding-right:0;
  padding-left:0;
  margin-right:auto !important;
  margin-left:auto !important;
  width: 100% !important;
}



.App {
  text-align: center;
  word-wrap: pre-line;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
