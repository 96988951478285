.bg-default-blue {
    background-color: #0074D0;
}

.bg-light-blue {
    background-color: #46A0EA;
}

.bg-mid-blue {
    background-color: #007EE6;
}

.bg-dark-blue {
    background-color: #1F4666;
}

.bg-darker-blue {
    /* background-color: #0062B3; */
    background-color: #007ade;
}

.bg-rs-blue {
    background-color: #007ade;
}

.fullContainer {
    width: 100% !important;
}

.font-white {
    color: white;
}

.black-links > a a:visited {
    color: black !important;
}